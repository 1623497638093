<template>
  <div>
    <!--    客户-->
    <div  v-if="hasAuthority('brand_hub_contract_list')">

      <searchPage @handleQuery='handleQuery' :type="'custom'" @handelAdd='handleAdd'></searchPage>

      <div class='list-table default-table radius_bg'>
        <el-table :data='dataList' ref='multipleTable' @sort-change='changeTableSort' row-key='id'
                  v-has-authority="'brand_hub_customer_list'" highlight-current-row v-loading='isLoading' border>
          <el-table-column align='center' label='序号' type='index' width='60'></el-table-column>
          <el-table-column align='center' min-width='180' prop='customer_name' show-overflow-tooltip label='公司名' sortable='custom'>
            <template slot-scope='{row}'>
              <span class='title'>{{ row.customer_name || '-' }}</span>
            </template>
          </el-table-column>
          <el-table-column align='center' min-width='160' prop='brand_id' show-overflow-tooltip label='品牌' sortable='custom'>
            <template slot-scope='{row}'>
              <span>{{ row.brand_name || '-' }}</span>
            </template>
          </el-table-column>
          <el-table-column align='center' min-width='120' prop='contact_id' show-overflow-tooltip label='联系人' sortable='custom'>
            <template slot-scope='{row}'>
              <span style='font-size: 14px'>{{ row.contact_user || '-' }}</span>
            </template>
          </el-table-column>
          <el-table-column align='center' prop='contact_info' min-width='120' label='联系方式'>
          </el-table-column>
          <el-table-column show-overflow-tooltip align='center' min-width='200' prop='delivery_info' label='物流信息'>
            <template slot-scope='{row}'>
              <span class='subtitle'>{{ row?.delivery_info }}</span>
            </template>
          </el-table-column>
          <el-table-column align='center' min-width='100' show-overflow-tooltip prop='submit_name'
                           label='负责人'></el-table-column>
          <el-table-column align='center' prop='created_at' min-width='140' show-overflow-tooltip
                           label='录入时间' sortable='custom'>
            <template slot-scope='{row}'>
              <span :title='`修改时间：${row.updated_at}`'>{{ row.created_at }}</span>
            </template>
          </el-table-column>
          <el-table-column align='center' prop='remark' min-width='150' show-overflow-tooltip
                           label='备注信息'></el-table-column>
          <el-table-column label='操作' align='center' min-width='130' fixed='right'>
            <template slot-scope='scope'>
              <el-button type='text' v-if="userPermissions.indexOf('brand_hub_customer_update')>-1"
                         @click='handleEdit(scope.row)' class='el-icon-edit'>修改
              </el-button>
              <el-button type='text' v-if="userPermissions.indexOf('brand_hub_customer_delete')>-1"
                         @click='handleDel(scope.row)' class='el-icon-delete'>删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-row>
          <el-col :span='24'>
            <m-pagination :limit.sync='pagingData.page_size'
                        :page.sync='pagingData.current_page'
                        :total='total'
                        @pagination='getList' />
          </el-col>
        </el-row>
      </div>
    </div>
    <el-dialog :visible.sync='dialogStore' :title="`客户信息-${dialogType === 'add' ? '添加' : '修改'}`"
               :before-close='closeFun' v-if='dialogStore' width='880px'
               center>
      <el-form ref='formStore' :model='formStore' :rules='rules' label-poionsit='right'
               label-width='110px'>
        <el-form-item label='公司名' prop='name'>
          <span v-if='formStore.id'>{{ formStore.name }}</span>
          <el-select v-else clearable v-model='formStore.name'
                     filterable
                     allow-create
                     default-first-option @change="setSelectValue('customer')" style='width: 100%' placeholder='请选择已有公司，若输入的公司名称未录入，则将新增一个'>
            <el-option
              v-for='item in nameOptions'
              :key='item.id'
              :label='item.name'
              :value='item.name'>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label='品牌' prop='brand_id'>
          <template slot='label'>

            <el-tooltip class="item" effect="light" content="若需新增或修改品牌，请到品牌管理页面进行修改" placement="left">
              <span>品牌<i class='el-icon-question'></i></span>
            </el-tooltip>
          </template>
          <span v-if='formStore.id'>{{ formStore.brand_name || '-' }}</span>
          <el-select v-else clearable v-model='formStore.brand_id'
                     filterable
                     default-first-option style='width: 100%' placeholder='请选择品牌，若检索不到，请先到品牌管理新增'>
            <el-option
              v-for='item in brandOptions'
              :key='item.id'
              :label='item.name'
              :value='item.id'>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label='联系人' prop='contact_user'>
          <el-input v-model='formStore.contact_user' clearable placeholder='请输入联系人' />
        </el-form-item>
        <el-form-item label='联系方式' prop='contact_info'>
          <el-input v-model='formStore.contact_info' clearable placeholder='请输入联系方式' />
        </el-form-item>
        <el-form-item label='物流信息' prop='delivery_info'>
          <el-input type='textarea' maxlength='1000' size='mini' rows='5'
                    v-model='formStore.delivery_info' clearable
                    placeholder='例子：联系人：耶小飞
联系电话：15268855555
邮寄地址：浙江省杭州市萧山区宁围街道建设二路信息港六期1号楼5楼'></el-input>
        </el-form-item>
        <el-form-item label='备注' prop='remark'>
          <el-input type='textarea' maxlength='500' rows='5' v-model='formStore.remark'
                    placeholder='请输入备注信息' clearable></el-input>
        </el-form-item>
        <el-form-item label='负责人' prop='submit_id'>
          <el-select
            clearable
            @change="setSelectValue('submit')"
            v-model='formStore.submit_name'
            filterable
            remote
            reserve-keyword
            placeholder='输入关键字，搜索负责人'
            :remote-method='remoteMethod'
            :loading='selectLoading'>
            <el-option
              v-for='item in options'
              :key='item.id'
              :label='item.nickname'
              :value='item.id'>
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot='footer' class='dialog-footer'>
        <el-button @click='dialogStore = false'>取 消</el-button>
        <el-button type='primary' @click='handleStore'>确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ExportBtn from '@/components/export/ExportBtn'
import searchPage from '@/pages/lb/brand/searchPage'
import ImportProductPanel from '@/pages/lb/product/ImportProductPanel.vue'


export default {
  name: 'investment',
  data() {
    return {
      dataList: [],
      downloadLoading: false,
      isLoading: false,
      total: 0,
      pagingData: {
        page_size: 15,
        current_page: 1
      },
      dialogStore: false,
      formStore: {},
      product: [],
      rules: {
        name: [{ required: true, message: '请填写公司名', trigger: 'blur' }],
        contact_info: [{ required: true, message: '请填写联系方式', trigger: 'blur' }],
        contact_user: [{ required: true, message: '请填写联系人', trigger: 'blur' }],
        brand_id: [{ required: true, message: '请选择品牌', trigger: 'blur' }]
      },
      searchData: {},
      dialogType: 'add',
      nameOptions: [],
      brandOptions: [],

      selectLoading: false,
      options: [],
      //排序
      orderSort: { id: 'desc' }

    }
  },

  components: {
    ImportProductPanel,
    ExportBtn, searchPage
  },
  computed: {
    ...mapGetters(['userPermissions', 'user_info'])
  },
  methods: {
    closeFun() {
      this.formStore = {}
      this.getList()
      this.dialogStore = false

    },
    setSelectValue(type) {
      let option = null
      switch (type) {
        case 'customer':
          option = this.nameOptions.find(item => item.name === this.formStore.name)
          if (option) {
            this.formStore.customer_id = option.id
            this.formStore.customer_name = option.name
            this.getContact()
          }
          break
        case 'submit':
          if (this.formStore.submit_name) {
            option = this.options.find(item => item.id === this.formStore.submit_name)
            if (option) {
              this.formStore.submit_id = option.id
              this.formStore.submit_name = option.nickname
            }
          }
          break
      }
    },
    async getContact() {
      let data = await this.$api.selectContact({ customer_id: this.formStore.customer_id })
      this.contactList = data.contacts
    },
    async remoteMethod(query) {
      if (query !== '') {
        this.selectLoading = true
        this.options = await this.$api.selectSubmit({ nickname: query })
        this.selectLoading = false
      } else {
        this.options = []
      }
    }
    ,

    handleSearchCondition() {
      let cond = {}
      if (this.searchData?.customer_id !== '') {
        cond.customer_id = this.searchData.customer_id
      } else {
        delete cond.fans_total
      }
      if (this.searchData?.submit_id !== '') {
        cond.submit_id = this.searchData.submit_id
      } else {
        delete cond.submit_id
      }

      return cond
    },
    handleQuery(select) {
      this.searchData = select
      this.getList()
    },
    changeTableSort({ prop, order }) {
      let orderSort = {}
      if (order) {
        orderSort[prop] = order === 'descending' ? 'desc' : 'asc'
      }
      this.orderSort = orderSort
      this.getList()

    },

    async getInfo(id) {
      this.formStore = await this.$api.getCustomInfo(id)
    },
    // eslint-disable-next-line no-unused-vars
    handleEdit(row) {
      this.dialogType = 'Edit'
      this.getInfo(row.id)
      // this.getSelectList()
      // this.loadBrandOption()

      // this.formStore = {
      //   name: row?.customers.name,
      //   contact_user: row.contact_user,
      //   contact_info: row.contact_info,
      //   delivery_info: row.delivery_info,
      //   contact_id: row.id,
      //   remark: row.remark,
      //   customer_id: row?.customers.id,
      //   submit_id: row?.submit_id,
      //   submit_name: row?.submit_name,
      //   brand_id: row?.brand?.id
      //
      // }
      this.dialogStore = true

    },
    async handleDel(row) {
      let data = {id: row.id }
      this.$confirm(`确认删除 客户（${row?.customer_name}）的品牌（${row.brand_name||'-'}）联系人（${row?.contact_user}）信息吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let id = await this.$api.delCustom(data)
        if (id) {
          this.$message.success('删除成功！')
          this.getList()
        } else {
          this.$message.error('未知错误,请联系管理员')
        }
      })
    },

    async getList() {
      this.isLoading = true
      this.dataList = []
      let searchData = this.handleSearchCondition()

      let { list, page_info } = await this.$api.getCustomList(Object.assign(searchData,this.pagingData,{order:this.orderSort}))
      this.dataList = list
      this.pagingData.current_page = page_info.current_page
      this.pagingData.page_size = page_info.page_size
      this.total = page_info.total
      this.isLoading = false
    },
    handleAdd() {
      this.getSelectList()
      this.loadBrandOption()
      this.dialogType = 'add'
      let userInfo = JSON.parse(localStorage.getItem('user_info'))
      this.$set(this.formStore, 'submit_id', userInfo.id)
      this.$set(this.formStore, 'submit_name', userInfo.nickname)
      this.dialogStore = true
    },
    async getSelectList() {
      this.nameOptions = await this.$api.selectCustomList()
    },
    async loadBrandOption() {
      this.brandOptions = await this.$api.selectBrand()
    },
    handleStore() {
      this.$refs.formStore.validate(async (valid) => {
        if (valid) {
          if (!this.formStore.submit_name) {
            let userInfo = JSON.parse(localStorage.getItem('user_info'))
            this.formStore.submit_id = userInfo.id
            this.formStore.submit_name = userInfo.nickname
          }
          const params = this.formStore
          if (this.dialogType === 'add') {
            let id = await this.$api.addCustom(params)
            if (id) {
              this.$message.success('新增成功！')
              this.closeFun()

            } else {
              this.$message.error('未知错误,请联系管理员')
            }

          } else {
            let id = await this.$api.updateCustom(params)
            if (id) {
              this.$message.success('修改成功！')
              this.closeFun()

            } else {
              this.$message.error('未知错误,请联系管理员')
            }

          }

          await this.getList()
        }
      })
    }
  },
  mounted() {
    this.getList()
  }
}
</script>

<style lang="scss" scoped>
@import "@/pages/lb/style/main.scss";



::v-deep.list-table .el-table thead {
  background: #eee;
  color: #666;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

::v-deep.list-table .el-table__header-wrapper .el-table__header thead tr th {
  background: #EBEEF5;
  padding: 0 0 !important;
}

::v-deep.list-table .el-table__fixed-header-wrapper .el-table__header thead tr th {
  background: rgba(248, 248, 248, 1);
  padding: 0 0 !important;
}

::v-deep.list-table th, .list-table td {
  /*padding: 4px 0;*/

}

::v-deep.list-table div.el-table td .cell {
  height: auto !important;
  line-height: 20px;
  font-weight: normal;
  font-size: 12px;

}

/*el-table__cell*/
::v-deep.list-table thead th .cell {
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  font-weight: 800;
  color: #0C0C0C;
}

::v-deep.list-table-child thead tr th .cell {
  height: 30px !important;
  line-height: 30px !important;
  font-size: 12px !important;
  /*font-weight: 800 !important; !**!*/
  color: #0C0C0C !important;
}

::v-deep.list-table thead th.is-leaf .cell {
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  font-weight: 800;
  color: #0C0C0C;
}


::v-deep.list-table-child thead th.is-leaf .cell {
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  font-weight: normal !important;
  color: #0C0C0C;

}

::v-deep.list-table-child .el-table__header-wrapper .el-table__header thead tr th {
  background: #fbf2f5;
  padding: 0 0 !important;
}

::v-deep.list-table .el-table thead tr, .list-table .el-table thead th {
  background: rgb(255, 255, 255) !important;
  padding: 10px 0 !important;
  color: rgba(50, 50, 50, 1);
  font-size: 14px;
  font-weight: normal;

}

::v-deep.list-table .el-table--striped .el-table__body tr.el-table__row--striped td {
  background: #EBEEF5;
}

</style>
